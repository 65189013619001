<template>
    <el-dialog :visible.sync="dialogVisible" :title="dialogTitle" :width="dialogWidth" :show-close="false"
        :before-close="beforeClose" :close-on-click-modal="false" :close-on-press-escape="false" @open="setOpen">
        <h3 slot="title" style="text-align: left">{{ dialogTitle }}</h3>
        <!-- 窗体头部 -->
        <div style="display: flex;align-items: center;">
            <!-- 一级类别选择器 -->
            <el-select v-model="category" placeholder="请选择" @change="handleCategoryChange">
                <el-option v-for="option in categoryOptions" :key="option" :label="option" :value="option">
                </el-option>
            </el-select>
            <!-- 二级类别选择器 -->
            <el-select style="margin-left: 50px" v-show="subCategoryOptions.length" v-model="subCategory"
                placeholder="请选择" @change="handleSubCategoryChange">
                <el-option v-for="option in subCategoryOptions" :key="option.value" :label="option.label"
                    :value="option.value"></el-option>
            </el-select>
            <!-- 三级类别选择器 -->
            <el-select style="margin-left: 50px" v-show="threeCategoryOptions.length" v-model="threeCategory"
                placeholder="请选择" @change="handle3CategoryChange">
                <el-option v-for="option in threeCategoryOptions" :key="option.value" :label="option.label"
                    :value="option.value"></el-option>
            </el-select>

            <!-- 重置按钮 -->
            <el-button style="margin-left: 50px;" type="primary" @click="handleReset">重置</el-button>
        </div>

        <div style="display: flex;flex-wrap: wrap;margin-top: 20px;margin-bottom: 20px;">
            <div style="background-color: #E7F4FF;padding:8px 10px ; border-radius: 20px; display: flex;align-items: center; margin-right: 10px;margin-bottom: 10px;"
                v-for="(item,index) in memorySelected" :key="index">
                <div> {{item.name}}</div>
                <i class="el-icon el-icon-circle-close" style="font-size: 20px;margin-left: 8px;"
                    @click="deleteItem({item,index})"></i>
            </div>
        </div>
        <!-- 窗体体部 -->
        <div class="data-table" v-loading="loadingStatus">
            <el-table ref="table" :show-header="false" :data="tableData" :height="tableHeight" :row-key="tableRowKey"
                >
                <!-- 复选框列 -->
                <el-table-column width="50">
                    <template slot-scope="scope">
                        <div style="width: 60px;">
                            <el-checkbox v-model="scope.row.select" @change="selectCheck($event,scope.row)">
                            </el-checkbox>
                        </div>

                    </template>
                </el-table-column>

                <!-- 列表字段 -->
                <el-table-column>
                    <template slot-scope="scope">{{ scope.row.name }}</template>
                </el-table-column>

            </el-table>
            <el-pagination v-if="total > 0" :current-page.sync="currentPage" :page-size="pageSize" :total="total"
                @current-change="handlePageChange" layout="prev, pager, next, total, jumper"
                style="margin-top: 20px; text-align: right" />
        </div>
        <!-- 窗体底部 -->
        <div slot="footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="handleConfirm">添加查询</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import {
        getCommonQualificationTypes2,
        getCommonQualificationTypes3,
        getCommonQualificationTypeProPage,
    } from "@/api/aptitude";
    export default {
        props: {
            // 一级类别选择器的选项
            categoryOptions: {
                type: Array,
                required: true,
            },
            // 窗体标题
            dialogTitle: {
                type: String,
                default: "选择资质",
            },
            // 窗体宽度
            dialogWidth: {
                type: String,
                default: "50%",
            },
            // 窗体高度
            dialogHeight: {
                type: String,
                default: "80%",
            },
            // 每页数据条数
            pageSize: {
                type: Number,
                default: 10,
            },
            // 是否多选
            multiple: {
                type: Boolean,
                default: false,
            },
            //外部已经选择的
            selectQuaList:{
                type: Array,
                default: [],
            },


        },
        data() {
            return {
                tableRowKey: 'id',
                form: {},
                // 窗体是否可见
                dialogVisible: false,
                // 当前选中的一级类别
                category: [],
                // 当前选中的二级类别
                subCategory: [],
                // 当前选中的三级类别
                threeCategory: [],
                // 子类型选项
                subCategoryOptions: [],
                // 三级分类选项
                threeCategoryOptions: [],
                // 数据列表
                tableData: [],
                // 数据列表的总条数
                total: 0,
                // 当前页数
                currentPage: 1,
                // 数据列表的行高
                tableHeight: 500,
                // 当前选中的记录
                selectedRecords: [],
                // 加载中状态
                loadingStatus: false,
                memorySelected: [],

                total: 0,
            };
        },
        computed: {

        },
        watch: {


        },

        methods: {
            //删除已经选中的
            deleteItem({ item, index }) {
                //父组件也调用 先判断存不存在 再删除
                let findIdx= this.memorySelected.findIndex(citem=>citem.name===item.name)
              
                if(findIdx===-1) return
                this.memorySelected.splice(findIdx, 1)
                this.cannelListCheck(item.id)
            },
            //取消列表选择
            cannelListCheck(id) {
                this.tableData.forEach(item => {
                    if (item.id === id) {
                        item.select = false
                    }
                })
            },
            //打开窗口
            setOpen() {
                this.subCategory = "";
            this.threeCategory = "";
         
            this.subCategoryOptions = []
            this.threeCategoryOptions = []
            this.fetchSubCategoriesData();
                this.fetchData();
            },
            beforeClose() { },
            handleConfirm() {
                if (!this.memorySelected.length) {
                    this.$message.warning("请选择资质！");
                    return;
                }
                this.handleOk();
                this.$emit("confirm", {
                    memorySelected: this.memorySelected,
                });
            },
          
            // 类别级联选择器的change事件处理
            handleCategoryChange(value) {
                this.category = value;
                this.subCategory = "";
                this.threeCategory = "";
                this.subCategoryOptions = []
                this.threeCategoryOptions = []
                this.fetchSubCategoriesData();
                this.fetchData();
            },
            handleSubCategoryChange(value) {
                this.subCategory = value;
                this.threeCategory = "";
                this.fetch3CategoriesData();
                this.fetchData();
            },
            handle3CategoryChange(value) {
                this.threeCategory = value;
                this.fetchData();
            },
            // 查询按钮的click事件处理
            handleQuery() {
                this.fetchData();
            },
            // 分页组件的change事件处理
            handlePageChange(page) {
                this.currentPage = page;
                this.fetchData();
            },

            //选中
            selectCheck($event, row) {
                if (row.select) {
                     //同个专业 等级只能选择一个 字符串拆分判断
                    let subString = row.name.split(/甲级|乙级|丙级|丁级|甲（Ⅰ）级|甲（Ⅱ）级|特级|一级|二级|三级/)
                    let sublength = subString[0].length
                    let levelName = subString[0]
                    let subStringLevel = row.name.substr(sublength, subString.length)
                    for (let i = 0; i < this.memorySelected.length; i++) {
                        if (this.memorySelected[i].name.includes(levelName) && this.memorySelected[i].name != row.name) {
                           //添加属性确保相同 用集合去重
                            row.select=false

                            return this.$message.warning("同种专业,等级不能多选!");
                        }
                    }
                    //外部的复合条件
                    for (let i = 0; i < this.selectQuaList.length; i++) {
                        if (this.selectQuaList[i].name.includes(levelName) && this.selectQuaList[i].name != row.name) {
                           //添加属性确保相同 用集合去重
                            row.select=false

                            return this.$message.warning("复合条件已存在相同专业!");
                        }
                    }
                  
                this.memorySelected.push(row)
               
            } else {
                this.memorySelected = this.memorySelected.filter(item => item.id != row.id)
            }
            // item.select=!item.select
        },

        // 确定按钮的click事件处理
        handleOk() {
            this.$emit("select", this.selectedRecords);
            this.dialogVisible = false;
        },
        open() {
            this.dialogVisible = true;
            this.handleReset();
        },
 
        handleReset() {
            this.category = "";
            this.subCategory = "";
            this.threeCategory = "";
            this.memorySelected = [];
            this.subCategoryOptions = []
            this.threeCategoryOptions = []
            this.fetchData();
        }, 
        // 查询二级
        async fetchSubCategoriesData() {

            try {

                let { data } = await getCommonQualificationTypes2({
                    cate1List: this.category ? [this.category] : [],
                });

                let subCategories = [];
                (data || []).forEach((item) => {
                    subCategories.push({
                        label: item,
                        value: item,
                    });
                });
                this.subCategoryOptions = subCategories;

                console.log('二级分类', this.subCategoryOptions)
            } catch (res) {
                console.log('二级分类异常')
                this.subCategoryOptions = [];
            } finally {

            }
        },
        // 查询三级分类
        async fetch3CategoriesData() {
            if (!this.category || !this.subCategory) {
                if (!this.category)
                    this.subCategoryOptions = [];
                if (!this.subCategory)
                    this.threeCategoryOptions = [];
                return;
            }
            try {
                let { data } = await getCommonQualificationTypes3({
                    cate1List: this.category ? [this.category] : [],
                    cate2List: this.subCategory ? [this.subCategory] : [],
                });
                let threeCategories = []
                console.log('======data1111s', data)
                data.forEach((item) => {
                    threeCategories.push({
                        label: item,
                        value: item,
                    });
                });
                this.threeCategoryOptions = threeCategories;
                console.log('=== this.threeCategoryOptions', this.threeCategoryOptions)
            } catch (res) {
                this.threeCategoryOptions = [];
            } finally {
            }
        },

        // 查询数据列表
        fetchData() {
            this.$nextTick(async () => {
                if (this.loadingStatus) return;
                this.loadingStatus = true;

                try {

                    let { data } = await getCommonQualificationTypeProPage({
                        size: this.pageSize,
                        current: this.currentPage,
                        cate1List: this.category ? [this.category] : [],
                        cate2List: this.subCategory ? [this.subCategory] : [],
                        cate3List: this.threeCategory ? [this.threeCategory] : [],
                    });
                    data.records.forEach(item => {
                        item.select = false
                    })
                    this.tableData = data.records;
                    this.total = data.total || 0;
                    this.$nextTick(() => {
                        console.log('---切换下一页时', this.memorySelected)
                        if (this.memorySelected.length) {
                            this.memorySelected.forEach((mitem) => {
                                this.tableData.forEach((item) => {
                                    if (mitem.id === item.id) {
                                        item.select = true
                                    }
                                });

                            })
                        }
                    });
                } catch (res) {
                    console.log('=====列表数据异常', res)
                    this.tableData = [];
                    this.total = 0;
                } finally {
                    this.loadingStatus = false;
                }
            });
        },
    },
    };
</script>
<style scoped>
    ::v-deep .el-button--primary {
        background-color: #007ef2;
        border-color: #007ef2;
    }
</style>