<template>
    <div>
        <el-autocomplete style="width: 360px;" v-model="personQualifi"
        :fetch-suggestions="querySearchAsync" clearable placeholder="输入资质关键字，如：工程设计"
        @select="selectQua"></el-autocomplete>
       
    </div>
</template>
<script>
    import { urlCommonQualificationTypeProPage,} from '@/api/recruit';
    export default {
        props: {

        },
        data(){
            return{
                personQualifi:'',
                cate1List:[],  
            }
        },
        methods: {
             //搜索远端数据
             async querySearchAsync(queryString, cb) {
                try {
                    let {data} = await urlCommonQualificationTypeProPage({current:1,size:100,name:queryString})
                    data.records.forEach(item => {
                        item.value= item.name
                    });
                    cb(data.records)
                } catch (error) {
                    
                }
               
            },
            //选择资质
            selectQua(item){
               
                this.$emit('backQuaValue',item)
            },
        },
    };
</script>

<style scoped>

</style>