<template>
    <div>
        <el-cascader v-model="value"  :key="refreshItem" @change="regionChange" :props="cascaderProps"
            ref="cascaderRegion" :placeholder="placeholder" style="width: 100%" 
            :options="regionList1"></el-cascader>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    name:"selectArea",
    props: {
        level: {
            type: String,
            default: 'area', // city 只获取到城市级别 默认获取三级分类
        },
        placeholder: {
            type: String,
            default: '请选择区域',
        },
        cascaderProps: {
            type: Object,
            default: function () {
                return {
                    lazy: false,
                    value: 'code',
                    label: 'name',
                    children: 'child',
                    expandTrigger: 'hover',
                    emitPath: true,
                };
            },
        },
    },
    data() {
        return {
            value: [],
            refreshItem: 0,
        };
    },
    methods: {
        ...mapActions('account', ['getRegion']),
        regionChange(e) {
            let vals = this.getCascaderObj(e, this.regionList);

           
            if(e.length==3){
                this.$refs.cascaderRegion.dropDownVisible = false;
            }
            this.$emit('onCallBack', vals, e);
        },
        // 设置数据回显
        // [19,20]
        setFiles(data) {
            if (Array.isArray(data)) {
                this.value = data;
                console.log('地址回显',data)
            } else {
                console.log('地址回显2',data)
                this.value = [];
            }
        },
        // 直接设置文本回显
        // setText(params){
        //   console.log('params===>',params)
        //   this.value = [19,20]
        // },
        getCascaderObj(val, opt) {
            let _this = this;
            return val.map(function (code, index, array) {
                for (var itm of opt) {
                    if (itm[_this.cascaderProps.value] == code) {
                        opt = itm.child;
                        return itm;
                    }
                }
                return null;
            });
        },
    },
    computed: {
        ...mapState('account', ['regionList']),
        regionList1() {
            let regionList = JSON.parse(JSON.stringify(this.regionList)) || [];
            if (regionList.length && this.level == 'city') {
                regionList.forEach((province) => {
                    let cities = province.child;
                    if (cities && cities.length) {
                        cities.forEach((city) => {
                            delete city.child;
                        });
                    }
                });
            }
            return regionList;
        },
    },
    mounted() {
        if (this.regionList.length) return
        this.getRegion();
    },
};
</script>
